import { useEffect, useState } from 'react'
import { Select } from '@mantine/core'
import _ from 'lodash'
const ALL = 'All'

export const VendorSalesPersonFilter = ({
  data,
  vendorSalesPerson,
  filterKeyPath,
}: {
  vendorSalesPerson: any
  data: any[]
  filterKeyPath: string
}) => {
  const [vendorSalesPersonEmail, setVendorSalesPersonEmail] = useState<string | null>('')

  const [filteredData, setFilteredData] = useState<any[]>(data)

  useEffect(() => {
    if (!vendorSalesPersonEmail) return

    if (vendorSalesPersonEmail === ALL) {
      setFilteredData(data)
      return
    }

    setFilteredData(
      data.filter((d) =>
        vendorSalesPerson[vendorSalesPersonEmail].companyIds.includes(_.get(d, filterKeyPath))
      )
    )
  }, [vendorSalesPersonEmail, filterKeyPath, data, vendorSalesPerson])

  return {
    SelectFilter: VendorSalesPersonSelect({
      vendorSalesPerson,
      length: data.length,
      vendorSalesPersonEmail,
      setVendorSalesPersonEmail,
    }),
    filteredData,
  }
}

export const VendorSalesPersonSelect = ({
  vendorSalesPerson,
  length,
  vendorSalesPersonEmail,
  setVendorSalesPersonEmail,
}: {
  vendorSalesPerson: any
  length?: number
  setVendorSalesPersonEmail: (email: string) => void
  vendorSalesPersonEmail: string | null
}) => {
  return (
    <Select
      data={[
        {
          label: ALL,
          value: ALL,
        },
      ].concat(
        Object.entries(vendorSalesPerson)
          .sort((a: any, b: any) => {
            if (a[1].firstname.toLowerCase() < b[1].firstname.toLowerCase()) return -1
            if (a[1].firstname.toLowerCase() > b[1].firstname.toLowerCase()) return 1
            return 0
          })
          .map(([email, value]) => {
            const { firstname, lastname } = value as {
              firstname: string
              lastname: string
            }
            return {
              label: `${firstname} ${lastname}`,
              value: email,
            }
          })
      )}
      value={vendorSalesPersonEmail}
      onChange={setVendorSalesPersonEmail}
      label={`Vendor sales person ${length !== undefined ? `(${length})` : ''}`}
    />
  )
}
