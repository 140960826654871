/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * elements (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect } from 'react'
import { Navigate, Route, Routes as ReactRoutes, useLocation } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'
import {
  ConfirmForgotPassword,
  ConfirmPage,
  ForgotPassword,
  LoginPage,
  Logout,
  TokenPage,
  useAuthCheck,
} from '@ospace/auth'
import {
  ClientCampaignsPage,
  CreateCampaignPageRouteHandler,
  DataIntelPageRouteHandler,
  EditCampaignPageRouteHandler,
  ProgramMetricsRouteHandler,
} from '@ospace/campaign'
import { NewOverviewPageRouteHandler } from '@ospace/campaign/pages/CampaignPage/NewOverviewPage'
import { CampaignsListPage } from '@ospace/campaign/pages/CampaignsListPage'
import { CreateClientPageRouteHandler, EditClientPageRouteHandler } from '@ospace/client'
import { DashboardPageRouteHandler } from '@ospace/client-dashboard'
import {
  CompaniesPageRouteHandler,
  CompanyDetailPageRouteHandler,
  ContactDetailPageRouteHandler,
  ContactReviewPageRouteHandler,
  ContactsPageRouteHandler,
  ContactUploadPageRouteHandler,
} from '@ospace/contact'
import { ContactUploadFailedRouteHandler } from '@ospace/contact/pages/ContactUploadFailedPage'
import { ContactUploadStatusPageRouteHandler } from '@ospace/contact/pages/ContactUploadStatusPage'
import { userTypes } from '@ospace/core-auth'
import { DistributionPipelinePageHandler } from '@ospace/distribution-pipeline'
import { LeadsDashBoardPageHandler } from '@ospace/leads-dashboard'
import {
  AccountInsightsPageRouteHandler,
  NotesPageRouteHandler,
  PotentialOpportunitesPageRouteHandler,
} from '@ospace/note'
import { CreateObjectiveResultPage } from '@ospace/objective'
import { SalesDashBoardPageHandler } from '@ospace/sales-dashboard'
import {
  CreateUserPageRouteHandler,
  EditUserPageRouteHandler,
  EditUserProfilePageRouteHandler,
  SsoInfoPage,
  UserPageRouteHandler,
} from '@ospace/user'
import * as Auth from 'aws-amplify/auth'

import { FileManagerPageRouteHandler } from '../../file-manager'
import { HomePageRouteHandler } from '../../home'
import { MyTeamsPageHandler } from '../../my-teams'
import {
  LegacyCampaignLayoutContainer,
  LegacyClientLayoutContainer,
  LegacyFullPageLayoutContainer,
} from '../components/LegacyLayoutContainers'
import { ErrorsPage } from '../pages/ErrorsPage'

import { PrivateRoutes } from './PrivateRoutes'

const Routes: FC = () => {
  useAuthCheck()
  const location = useLocation()

  useEffect(() => {
    Auth.fetchUserAttributes()
      .then((attr) => {
        if (!attr.email) return
        datadogLogs.logger.info(location.pathname, {
          userEmail: attr.email,
          userAgent: window.navigator.userAgent,
        })
      })
      .catch(() => {})
  }, [location])

  return (
    <ReactRoutes>
      {/* PrivateRoutes handles session loading and redirects if no session */}
      {/* TODO(ddc): handle session errors in PrivateRoutes */}
      <Route path='/' element={<PrivateRoutes />}>
        <Route path='/' element={<HomePageRouteHandler />} />
        <Route
          path='/campaigns'
          element={
            <LegacyFullPageLayoutContainer role={userTypes.Admin}>
              <CampaignsListPage />
            </LegacyFullPageLayoutContainer>
          }
        />
        <Route
          path='/campaign/edit/:id'
          element={
            <LegacyCampaignLayoutContainer>
              <EditCampaignPageRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route path='/campaign/view/:id' element={<NewOverviewPageRouteHandler />} />
        <Route
          path='/campaign/:id/contact/upload'
          element={
            <LegacyCampaignLayoutContainer>
              <ContactUploadPageRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/campaign/:id/contact/upload/:importId'
          element={
            <LegacyCampaignLayoutContainer>
              <ContactUploadStatusPageRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/campaign/:id/contact/upload/:importId/failed'
          element={
            <LegacyCampaignLayoutContainer>
              <ContactUploadFailedRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/campaign/:id/contact'
          element={
            <LegacyCampaignLayoutContainer>
              <ContactsPageRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/campaign/:id/company'
          element={
            <LegacyCampaignLayoutContainer>
              <CompaniesPageRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/campaign/:id/contact/review'
          element={
            <LegacyCampaignLayoutContainer>
              <ContactReviewPageRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/campaign/:id/contact/:contactId'
          element={
            <LegacyCampaignLayoutContainer>
              <ContactDetailPageRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/campaign/:id/company/:companyId'
          element={
            <LegacyCampaignLayoutContainer>
              <CompanyDetailPageRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/campaign/:id/objective/create'
          element={
            <LegacyCampaignLayoutContainer>
              <CreateObjectiveResultPage />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/campaign/:id/files'
          element={
            <LegacyCampaignLayoutContainer>
              <FileManagerPageRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/campaign/data-intel/:id/dashboard/:intelType'
          element={
            <LegacyCampaignLayoutContainer>
              <DataIntelPageRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/campaign/:id/note'
          element={
            <LegacyCampaignLayoutContainer>
              <NotesPageRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/campaign/:id/potential-opportunities'
          element={
            <LegacyCampaignLayoutContainer>
              <PotentialOpportunitesPageRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/campaign/:id/account-insights'
          element={
            <LegacyCampaignLayoutContainer>
              <AccountInsightsPageRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/client/view/:id'
          element={
            <LegacyClientLayoutContainer>
              <ClientCampaignsPage />
            </LegacyClientLayoutContainer>
          }
        />
        <Route path='/program/view/:id' element={<NewOverviewPageRouteHandler />} />
        <Route
          path='/program/view/:id/program-metrics'
          element={
            <LegacyCampaignLayoutContainer>
              <ProgramMetricsRouteHandler />
            </LegacyCampaignLayoutContainer>
          }
        />
        <Route
          path='/client/create'
          element={
            <LegacyFullPageLayoutContainer role={userTypes.Admin}>
              <CreateClientPageRouteHandler />
            </LegacyFullPageLayoutContainer>
          }
        />
        <Route
          path='/client/edit/:id'
          element={
            <LegacyClientLayoutContainer>
              <EditClientPageRouteHandler />
            </LegacyClientLayoutContainer>
          }
        />
        <Route
          path='/client/:id/campaign/create'
          element={
            <LegacyClientLayoutContainer>
              <CreateCampaignPageRouteHandler />
            </LegacyClientLayoutContainer>
          }
        />
        <Route
          path='/user'
          element={
            <LegacyFullPageLayoutContainer role={userTypes.Admin}>
              <UserPageRouteHandler />
            </LegacyFullPageLayoutContainer>
          }
        />
        {/* /user/edit/:id is for Admins editing profiles */}
        <Route
          path='/user/edit/:id'
          element={
            <LegacyFullPageLayoutContainer role={userTypes.Admin}>
              <EditUserPageRouteHandler />
            </LegacyFullPageLayoutContainer>
          }
        />
        {/* /user/edit is the route used by users to edit their own profile. */}
        {/* Admin users will not be able to see the admin menu on this route */}
        <Route
          path='/user/edit'
          element={
            <LegacyFullPageLayoutContainer>
              <EditUserProfilePageRouteHandler />
            </LegacyFullPageLayoutContainer>
          }
        />
        <Route
          path='/user/create'
          element={
            <LegacyFullPageLayoutContainer role={userTypes.Admin}>
              <CreateUserPageRouteHandler />
            </LegacyFullPageLayoutContainer>
          }
        />
        <Route
          path='/sso'
          element={
            <LegacyFullPageLayoutContainer>
              <SsoInfoPage />
            </LegacyFullPageLayoutContainer>
          }
        />
        <Route path='/client/:clientId/dashboard' element={<DashboardPageRouteHandler />} />
        <Route
          path='/client/:clientId/distribution-pipeline'
          element={<DistributionPipelinePageHandler />}
        />
        <Route path='/client/:clientId/sales-dashboard' element={<SalesDashBoardPageHandler />} />
        <Route path='/client/:clientId/leads-dashboard' element={<LeadsDashBoardPageHandler />} />
        <Route path='/client/:clientId/my-teams' element={<MyTeamsPageHandler />} />
        {/* legacy redirects */}
        <Route path='/home' element={<Navigate replace to='/' />} />
        <Route path='/dashboard' element={<Navigate replace to='/' />} />
      </Route>
      <Route path='/error' element={<ErrorsPage />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/auth/login' element={<LoginPage />} />
      <Route path='/auth/token' element={<TokenPage />} />
      <Route path='/auth/forgot-password' element={<ForgotPassword />} />
      <Route path='/auth/confirm-forgot-password' element={<ConfirmForgotPassword />} />
      <Route path='/auth/confirm/:username?' element={<ConfirmPage />} />
      <Route path='*' element={<ErrorsPage />} />
    </ReactRoutes>
  )
}

export { Routes }
