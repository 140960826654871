import { Col, Row, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { CampaignPage } from '@ospace/campaign/pages/CampaignPage/CampaignPage'
import { Notes, useNotesForCompany } from '@ospace/note'
import { ErrorBoundary } from '@ospace/shared'

import { CompanyBox } from '../components/CompanyBox'
import { MinimalContactsTable } from '../components/MinimalContactsList'
import { useCompanies, useContactsForCompanies } from '../hooks/useContacts'

type CompanyDetailPageProps = { campaignId: number; companyId: number }

const NotesBox = (props: { campaignId: number; companyId: number }) => {
  const { isFetching, data: notes } = useNotesForCompany(props.campaignId, props.companyId)

  return isFetching ? (
    <div className='d-flex align-items-center justify-content-center'>
      <Spinner animation='grow'></Spinner>
    </div>
  ) : notes.length > 0 ? (
    <Row className={`card`}>
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3'>Call notes</span>
        </h3>
      </div>
      <div
        className={`card-body`}
        style={{
          overflow: 'auto',
        }}
      >
        <Notes notes={notes} />
      </div>
    </Row>
  ) : (
    <></>
  )
}

const ContactBox = (props: { campaignId: number; companyId: number }) => {
  const { isFetching, data: contacts } = useContactsForCompanies(props.campaignId, props.companyId)
  return isFetching ? (
    <div className='d-flex align-items-center justify-content-center'>
      <Spinner animation='grow'></Spinner>
    </div>
  ) : contacts.length > 0 ? (
    <Row className={`card mb-4`}>
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3'>Contact List</span>
        </h3>
      </div>

      <MinimalContactsTable contacts={contacts} />
    </Row>
  ) : (
    <></>
  )
}

export const CompanyDetailPage = (props: CompanyDetailPageProps) => {
  const { isFetching, data } = useCompanies(props.campaignId)

  return (
    <CampaignPage>
      {isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : (
        <Row>
          <Col sm={4}>
            <CompanyBox company={data.companies[0]} collpase={false} />
          </Col>
          <Col sm={8}>
            <ContactBox campaignId={props.campaignId} companyId={props.companyId} />
            <NotesBox campaignId={props.campaignId} companyId={props.companyId} />
          </Col>
        </Row>
      )}
    </CampaignPage>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId: number = Number(params?.id)
  const companyId: number = Number(params?.companyId)

  return <CompanyDetailPage campaignId={campaignId} companyId={companyId} />
}

export const CompanyDetailPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
