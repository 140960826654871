import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Center, Loader } from '@mantine/core'
import { ErrorBoundary } from '@ospace/shared'

import { FilteredNotes } from '../components/FilteredNotes'
import { usePotentialOpportunities } from '../hooks'

type PotentialOpportunitesBoxPageProps = { campaignId: number }

const PotentialOpportunitesPage = (props: PotentialOpportunitesBoxPageProps) => {
  const { isFetching, data: potentialOpportunities } = usePotentialOpportunities(props.campaignId)

  if (isFetching) {
    return (
      <Center maw='100vh' h='100vh' mx='auto'>
        <Loader variant='bars' />
      </Center>
    )
  }
  return (
    <FilteredNotes
      isFetching={isFetching}
      data={potentialOpportunities}
      label={'Potential Opportunities '}
    />
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId: number = Number(params?.id)

  return <PotentialOpportunitesPage campaignId={campaignId} />
}

export const PotentialOpportunitesPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
