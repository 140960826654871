import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Center, Loader } from '@mantine/core'
import { CampaignPage } from '@ospace/campaign/pages/CampaignPage/CampaignPage'
import { ErrorBoundary, VendorSalesPersonFilter } from '@ospace/shared'

import { CompaniesTable } from '../components/Companies'
import { useCompanies } from '../hooks/useContacts'

type Props = {
  campaignId: number
  data: {
    companies: any[]
    vendorSalesPerson: {
      enabled: boolean
      data: any
    }
  }
}

export const CompaniesPage = (props: Props) => {
  const { SelectFilter, filteredData } = VendorSalesPersonFilter({
    data: props.data?.companies || [],
    vendorSalesPerson: props.data?.vendorSalesPerson?.data || [],
    filterKeyPath: 'id',
  })

  return (
    <CampaignPage>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3
            className='card-title align-items-start flex-column'
            style={{
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <span className='card-label fw-bolder fs-3'>All Companies</span>
              {props.data.vendorSalesPerson.enabled && { ...SelectFilter }}
            </div>
          </h3>
        </div>
        <div className='card-body'>
          <CompaniesTable companies={filteredData} />
        </div>
      </div>
    </CampaignPage>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId = Number(params?.id)
  const { isFetching, data } = useCompanies(campaignId)
  if (isFetching) {
    return (
      <Center maw='100vh' h='100vh' mx='auto'>
        <Loader variant='bars' />
      </Center>
    )
  }
  return <CompaniesPage campaignId={campaignId} data={data} />
}

export const CompaniesPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
