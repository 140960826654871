import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Center, Loader } from '@mantine/core'
import { ErrorBoundary } from '@ospace/shared'

import { FilteredNotes } from '../components/FilteredNotes'
import { useAccountInsights } from '../hooks'

type AccountInsightsBoxPageProps = { campaignId: number }

const AccountInsightsPage = (props: AccountInsightsBoxPageProps) => {
  const { isFetching, data: AccountInsights } = useAccountInsights(props.campaignId)
  if (isFetching) {
    return (
      <Center maw='100vh' h='100vh' mx='auto'>
        <Loader variant='bars' />
      </Center>
    )
  }
  return <FilteredNotes isFetching={isFetching} data={AccountInsights} label={'Account Insights'} />
}

const Controller = () => {
  const params: any = useParams()
  const campaignId: number = Number(params?.id)

  return <AccountInsightsPage campaignId={campaignId} />
}

export const AccountInsightsPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
