import { FC, forwardRef, HTMLProps, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Table } from '@ospace/shared'
import { VendorSalesPersonSelect } from '@ospace/shared/components/VendorSalesPersonFilter'
import { ColumnDef, OnChangeFn, PaginationState, RowSelectionState } from '@tanstack/react-table'

import { useContacts } from '../hooks/useContacts'
import { Contact } from '../schemas/Contact'

type ContactsTableProps = {
  campaignId: number
  setRowSelection: OnChangeFn<RowSelectionState>
  rowSelection: RowSelectionState
}

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate, rest.checked])

  return <input type='checkbox' ref={ref} className={className + ' cursor-pointer'} {...rest} />
}

const ContactsTable: FC<any> = forwardRef(
  ({ setRowSelection, campaignId, rowSelection }: ContactsTableProps, ref) => {
    const { search, pathname } = useLocation()
    const companyDomain = new URLSearchParams(search).get('account') || ''
    const columns: ColumnDef<Contact>[] = [
      {
        id: 'select',
        header: ({ table }) => (
          <IndeterminateCheckbox
            style={{ marginLeft: 3 }}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        size: 20,
        cell: ({ row }) => (
          <div className='px-1'>
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        header: 'Full Name',
        cell: (info: any) => {
          const id = info.row.original.id
          return (
            <Link to={`${pathname}/${id}`} className='text-hover-priamry cursor-pointer'>
              {info.getValue()}
            </Link>
          )
        },
      },
      { accessorKey: 'jobTitle', header: 'Job Title' },
      { accessorKey: 'emailAddress', header: 'Email Address' },
      { accessorKey: 'mobilePhone', header: 'Mobile Phone' },
      { accessorFn: (row) => row.company?.name, header: 'Company Name' },
      { accessorFn: (row) => row.company?.website, header: 'Website' },
    ]
    const [pagination, setPagination] = useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
    const [filter, setServerSideFilter] = useState('')
    const [vendorSalesPersonEmail, setVendorSalesPersonEmail] = useState('')
    // server side filter search criteria are static columns defined by the db query
    // enableGlobalFilter in column definition does not apply to server side filter
    const dataQuery = useContacts(campaignId, pagination, vendorSalesPersonEmail, filter)
    return (
      <div className={`card`}>
        <div
          className='card-body py-3'
          style={{
            paddingTop: 0,
          }}
        >
          <div className='table-responsive'>
            <Table
              isLoading={dataQuery.isLoading}
              isFetching={dataQuery.isFetching}
              ref={ref}
              columns={columns}
              data={dataQuery?.data?.records || []}
              filter={companyDomain}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              pagination={pagination}
              setPagination={setPagination}
              rowCount={dataQuery?.data?.recordCount || 10}
              manualPagination={true}
              setServerSideFilter={setServerSideFilter}
              vendorSalesPersonFilter={
                dataQuery?.data?.vendorSalesPerson?.enabled
                  ? VendorSalesPersonSelect({
                      vendorSalesPerson: dataQuery?.data?.vendorSalesPerson.data || [],
                      vendorSalesPersonEmail,
                      setVendorSalesPersonEmail,
                    })
                  : undefined
              }
            />
          </div>
        </div>
      </div>
    )
  }
)

export { ContactsTable }
