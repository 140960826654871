import { Col, Row, Spinner } from 'react-bootstrap'
import { CampaignPage } from '@ospace/campaign/pages/CampaignPage/CampaignPage'
import { VendorSalesPersonFilter } from '@ospace/shared'

import { Notes } from './Notes'

export const FilteredNotes = ({
  isFetching,
  data,
  label,
}: {
  isFetching: boolean
  label: string
  data: {
    notes: any[]
    vendorSalesPerson: {
      enabled: boolean
      data: any
    }
  }
}) => {
  const { SelectFilter, filteredData } = VendorSalesPersonFilter({
    data: data?.notes || [],
    vendorSalesPerson: data?.vendorSalesPerson?.data || [],
    filterKeyPath: 'company.id',
  })

  return (
    <CampaignPage>
      {isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : (
        <Row>
          <Col sm={12}>
            <div className={`card`}>
              <div className='card-header border-0'>
                <h3
                  className='card-title align-items-start flex-column'
                  style={{
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      width: '100%',
                      paddingTop: '20px',
                    }}
                  >
                    <span className='card-label fw-bolder fs-3'>{label}</span>
                    {data.vendorSalesPerson.enabled && { ...SelectFilter }}
                  </div>
                </h3>
              </div>
              <div className={`card-body`} style={{ overflow: 'scroll' }}>
                <Notes notes={filteredData} />
              </div>
            </div>
          </Col>
        </Row>
      )}
    </CampaignPage>
  )
}
